// Default theme colors
$theme-colors: (
  "coolblack": #090a0b,
  "spacegrey": #353535,
  "snowwhite": #ffffff,
);

// Default brand colors
$brand-colors: (
  "orangered": #ff5100,
  "greatgold": #f2cb05,
  "greenblue": #389092,
);

$theme-name: "";
$brand-name: "";
$theme-color: map-get($theme-colors, "snowwhite");
$brand-color: map-get($brand-colors, "orangered");

@if map-has-key($theme-colors, $theme-name) {
  $theme-color: map-get($theme-colors, $theme-name);
} @else if str-index($theme-name, "#") == 1 {
  $theme-color: #ffffff;
}

@if map-has-key($brand-colors, $brand-name) {
  $brand-color: map-get($brand-colors, $brand-name);
} @else if str-index($brand-name, "#") == 1 {
  $brand-color: #ff5100;
}

$content-width: 920px;

@import "yat";

.page-banner-heading {
    background-color: rgba(0, 7, 33, .7);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
};

.page-banner-subheading {
    background-color: rgba(0, 7, 33, .7);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
};

.site-footer-inner {
    text-align: center;
};

.post-related {
    display: none;
};

body {
    font-size: 16px;
};

.post-content h1 {
    font-size: 32px;
}